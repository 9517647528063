@import "styles/color.module";
@import "styles/utils.module";
@import "components/sections/curation/CurationSection.module";

.root {
  padding: 24px 16px;
}

.title {
  display: block;
  font-size: 32px;
  font-weight: 500;
  line-height: 100%;
  color: white;
  margin-top: 16px;
}

.bannerItem {
  width: 100%;
  height: 100vh;
  display: block;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.mapContainer {
  margin-top: 16px;
  padding: 0 16px 51px 16px;
}

.googleMapTextContainer {
  height: 40px;
  margin-top: 10px;
}

.googleMapTitleWrapper{
  @extend .sectionTitleWrapper
}

.googleMapTitle{
  @extend .sectionTitle;
}

.googleMapSubTitle{
  @extend .sectionDescription;
}

.googleMapButton {
  background-color: #F5F6F9;
  height: 42px;
  border-radius: 5px;
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
  align-items: center;
  padding-left: 10.24px;
  padding-right: 10.24px;
}

.googleMapButtonText {
  color: black;
  font-size: 14px;
  line-height: 100%;
  font-weight: 500;
}
.titleContainer {
  position: absolute;
  left: 30px;
  top: 10%;
  transform: translateY(-50%);
  font-size: 40px;
}
.textContainer {
  z-index: 100;
  position: absolute;
  width: 100%;
  padding-top: 63px;
  padding-bottom: 40px;
  bottom: 0;
  background: linear-gradient(180deg, rgba(217, 217, 217, 0) 8.52%, rgba(0, 0, 0, 0.8) 100%);
}

@mixin bannerPCBreakpoint {
  //@media (max-width: #{1200px}) {
  @media (max-width: #{999999px}) {
    @content;
  }
}

@mixin bannerTabletBreakpoint {
  //@media (max-width: #{892px}) {
  @media (max-width: #{999999px}) {
    @content;
  }
}


@mixin bannerMobileBreakpoint {
  //@media (max-width: #{454px}) {
  @media (max-width: #{999999px}) {
    @content;
  }
}


.viewMapButtonWrapper{
  z-index: 100;
  margin-top: 20px;
  width: 104px;
}
.lookAroundButtonWrapper{
  margin-top: 20px;
  width: 144px;
}
.bannerText {
  white-space: pre-wrap;
  display: block;
  line-height: 120%;
  font-size: 32px;
  font-weight: 700;
  color: white;
}

.bannerSubText {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
  color: white;
}
.bannerTitle {
  position: absolute;
  z-index: 100;
  top: 18px;
  margin-left: 16px;
  width: 81px;
  height: 13.23px;
}
.taxFree {
  position: absolute;
  z-index: 100;
  top: 18px;
  margin-right: 16px;
  right: 0;
  width: 40px;
  height: 40px;
}
.curationTitle {
  display: block;
  font-size: 20px;
  //font-size: 1vw;
  font-weight: 500;
  line-height: 120%;
}

.slideWrapper {
  padding: 40px 0;
}
.swiper {
  height: 100vh;
}

.swiperHorizontal {
  position: absolute;
  left: auto !important;
  right: 16px !important;
  bottom: 16px !important;
  //transform: translateY(50%);
  z-index: 10;
  @extend .flexRow;
  width: auto !important;
  @extend .cursorPointer;
}

.hiddenSwiper {
  display: none;
}

.paginationIndicator {
  width: 34px !important;
  background-color: rgba($gray800, 0.60);
  padding: 4px 0;
  font-size: 12px;
  font-weight: 600;
  line-height: 100%;
  border-radius: 16px;
  color: $border;
  display: inline-block;
}

.paginationSeeMoreButton {
  border-radius: 9999px;
  background-color: $brand;
  width: 20px;
  height: 20px;
  margin-left: 6px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  & path {
    fill: white;
  }
}

.swiperCurrent {
  color: white;
}

.swiperBullet {
  display: inline-block;
  width: 40px;
  height: 3px;
  background-color: rgba($typo4, 0.35);
}

.swiperBulletActive {
  background-color: white;
}

.paginationIndicatorWrapper {
  @extend .flexRow;
}

.placeholder {
  background-color: $gray300;
}


.small {
  padding-top: 16.37%;
}

.firstSectionContainer {
  position: relative;
  font-family: "Helvetica Neue" !important;
  height: 100vh;
}

.textWrapper {
  margin-left: 27px;
}
